/*--------------------------
    Swicher Styles  
----------------------------*/

.active-dark-mode .my_switcher ul li .setColor.dark {
    display: none;
}
.active-light-mode .my_switcher ul li .setColor.light {
    display: none;
}
.active-light-mode .my_switcher ul li a.dark img {
    display: block;
}
.my_switcher ul li a.dark img {
    display: none;
    filter: brightness(100);
}

.my_switcher {
    border: 0 none;
    padding: 0;
    margin-left: 10px;
    width: 40px;
    background: var(--color-primary);
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    border-radius: 100%;
    @media #{$sm-layout} {
        margin-left: 5px;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 100%;
        li {
            margin-top: 0;
            margin-bottom: 0;
            a {
                display: block;
                position: relative;
                z-index: 2;
                background: var(--color-primary);
                width: 40px;
                height: 40px;
                border-radius: 100%;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                &.active {
                    display: none;
                }
            }
        }
    }
}